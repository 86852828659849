const it = {
  maintenance: {
    plan: {
      tableCols: {
        type: 'Tipo anomalia',
        dueDate: 'Pianificato',
        plannedDate: 'Scadenza',
      }
    },
    actionScheduledJob: {
      type: 'Tipo anomalia',
      dueDate: 'Pianificato',
      plannedDate: 'Scadenza',
    },
    addScheduledJob: {
      dueDate: 'Pianificato',
    },
    scheduledJobDetail: {
      recap: {
        type: 'Tipo anomalia',
        dueDate: 'Pianificato',
        plannedDate: 'Scadenza',
      }
    }
  }
}

export default it
